import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PersonalComponent } from './personal/personal.component';
import { ProfessionalComponent } from './professional/professional.component';
import { CoachingComponent } from './coaching/coaching.component';
import { FunComponent } from './fun/fun.component';

const appRoutes: Routes = [
  {path: 'personal', component: PersonalComponent },
  {path: 'professional', component: ProfessionalComponent},
  {path: 'coaching', component: CoachingComponent},
  {path: 'fun', component: FunComponent},
  {path: '**', redirectTo: '/personal'}
];

@NgModule({
  declarations: [
    AppComponent,
    PersonalComponent,
    ProfessionalComponent,
    CoachingComponent,
    FunComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
